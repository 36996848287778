<template>
  <b-card no-body title="Support Messages">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1 justify-content-end">
          <b-input-group-append class="mr-2">
            <b-form-checkbox :checked="getShowAll" @change="onShowAllClicked"> All </b-form-checkbox>
          </b-input-group-append>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="onFindSupportRequests">
            <b-spinner v-if="getStoreLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getStoreLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getStoreLoading" rounded="sm" opacity="0.8">
      <b-table
        responsive
        :fields="fields"
        :items="getSupportRequestsList"
        :per-page="getSupportTablePerPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        sort-icon-left
        no-local-sorting
        :key="dataUpdated"
      >
        <template #cell(message)="data">
          {{ data.item.requestType }}
          <br />

          <b-link @click="onTicketRowClicked(data)">
            {{ showMessageSummary(data.value) }}
          </b-link>
        </template>
        <template #cell(created)="data">
          {{ showDateInFormat(data.value) }}
        </template>
        <!-- facility information -->
        <template #cell(requestingFacilityName)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-weight-bolder">
                {{ data.item.requestingFacilityName }}
              </div>
              <div class="font-small-2 text-muted">
                {{ data.item.requestingFacilityLicense }}
              </div>
              <div v-if="data.item.requestingUser" class="font-small-2 text-muted">Requesting user: {{ data.item.requestingUser }}</div>
            </div>
          </div>
        </template>
        <template #cell(status)="data">
          Responded:
          <template v-if="data.item.responded">
            <feather-icon icon="CheckCircleIcon" size="18" class="text-success" />
          </template>
          <template v-else>
            <feather-icon icon="XCircleIcon" size="18" class="text-danger" />
          </template>
          <br />
          Resolved:
          <template v-if="data.item.resolved">
            <feather-icon icon="CheckCircleIcon" size="18" class="text-success" />
          </template>
          <template v-else>
            <feather-icon icon="XCircleIcon" size="18" class="text-danger" />
          </template>
        </template>

        <template #cell(conversation)="data"> # Messages: {{ data.value && data.value.length > 0 ? data.value.length : 0 }} </template>
        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1"> <strong>Message Details: </strong>{{ row.item.message }} </b-col>
            </b-row>

            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails"> Hide Details </b-button>
          </b-card>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="getSupportTablePerPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="totalRows"
            :per-page="getSupportTablePerPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { useRouter } from "@core/utils/utils";

import {
  setFilterBy,
  setShowAll,
  getSupportRequestsList,
  getStoreLoading,
  getSupportTablePerPage,
  getShowAll,
  sortBy,
  setLastItem,
  sortOrder,
  onLoadSupportRequests
} from "@/views/support/useSupportRequests";
export default {
  name: "SupportRequests",
  directives: {
    Ripple
  },
  setup() {
    let filter = ref("");
    let currentPage = ref(1);
    let totalRows = ref(1);
    let pageOptions = [3, 5, 10, 25];
    const { router } = useRouter();
    let dataUpdated = ref(0);

    const fields = [
      //'mark_resolved',
      {
        key: "message",
        label: "Message Text"
      },
      {
        key: "requestingFacilityName",
        label: "Requesting Facility",
        sortable: true
      },
      {
        key: "created",
        label: "Creation Date",
        sortable: true
      },
      {
        key: "status",
        label: "Status",
        sortable: true
      },
      "conversation"
    ];

    const isSortDesc = ref(false);

    const onInputSearch = (val) => {
      setFilterBy(val);
      setLastItem(0);
      onFindSupportRequests();
    };

    watch(filter, (newValue, oldValue) => {
      onInputSearch(newValue);
    });

    watch(sortBy, (newValue, oldValue) => {
      onFindSupportRequests();
    });

    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      onFindSupportRequests();
    });

    const onShowAllClicked = (val) => {
      setShowAll(val);
      onFindSupportRequests();
    };

    const showMessageSummary = (val) => {
      let sp = val ? val.indexOf("\n") : 75;
      sp = sp < 0 ? Math.min(75, val.length) : sp;
      return val.substr(0, sp) + "...";
    };

    const showDateInFormat = (date) => {
      return moment(date).format("YYYY-MM-DD hh:mm:ss a");
    };

    const onFindSupportRequests = async () => {
      await onLoadSupportRequests({ prevPage: false, nextPage: false });
      dataUpdated.value++;
    };

    const onPageChanged = async (val) => {
      if (currentPage > val) {
        await onLoadSupportRequests({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadSupportRequests({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    const onTicketRowClicked = (row) => {
      router.push({ name: "support-conversation", params: { ticketRow: row.item } });
    };

    onMounted(() => {
      onFindSupportRequests();
    });

    return {
      getSupportRequestsList,
      getStoreLoading,
      fields,
      filter,
      getSupportTablePerPage,
      currentPage,
      pageOptions,
      totalRows,
      getShowAll,
      setShowAll,
      showMessageSummary,
      showDateInFormat,
      onFindSupportRequests,
      onInputSearch,
      onShowAllClicked,
      onPageChanged,
      sortBy,
      sortOrder,
      isSortDesc,
      onTicketRowClicked,
      dataUpdated
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
